<template>
  <div class="privacy">
    <h1>Brazil Privacy Notice</h1>
    <p>This Brazil Privacy Notice (“Notice”) applies to personal data processing activities under Brazilian law and supplements our Privacy Policy.</p>
    <p>Under the Brazilian General Data Protection Law (the “LGPD”), you have the right to access, rectify, port, erase, and confirm that we process your data. Learn more about your rights and find out how you can exercise your rights by visiting our iPhone,</p>
    <p>Android and articles in our Help Center. In certain circumstances, you also have the right to object to and to restrict the processing of your personal data. Our Privacy Policy provides information about how we share data with third parties. To request more information about our data practices, click here.</p>
    <p>The data controller responsible for your information is Holowits Technology Co., Ltd. You also have the right to petition the Brazilian Data Protection Authority (“DPA”) by contacting the DPA directly. This Notice takes effect on the LGPD’s effective date.</p>
  </div>
</template>

<script>
export default {
name: 'S200Brazil'
}
</script>

<style lang="scss" scoped>
.privacy {
    width: 70%;
    margin: auto;
    h1 {
        margin: 50px 0;
        text-align: center;
    }
}
</style>